<template>
<div>
    <div style="width:100%;position:absolute;top:0px;height:calc(8%);">
              <div class="input-group input-group-lg" style="z-index:2;">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon" data-src="assets/img/icons/theme/general/search.svg">
                        <title>Icon For Search</title>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                            <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                    </svg>
                  </span>
                </div>
                <input type="search" v-model="$store.state.query" @keyup="searchQuery" class="form-control" placeholder="Search for articles" aria-label="Search" aria-describedby="basic-addon-1">
              </div>
    </div>

    <div style="position:absolute;bottom:0px;height:calc(92%);width:100%;overflow-x:hidden;overflow-y:scroll;">
        <div v-if="$store.state.query.length < 4 && !loading" class="row p-4 ml-4 mt-1">
        
          <div v-for="(item, index) in this.$store.state.items" :key="item.id" class="col-sm-6 col-lg-6 align-items-center mb-3 list-group-item-action p-2">
            <div @click="clickRouteFunc(item, index, 'items')" :class="'ref'+item.id" style="cursor:pointer;">
              <div class="row">
                <div class="col-md-3" style="display:flex:justify-content:center">
                  <img v-if="item.img_src" :src="item.img_src" class="rounded mr-3" style="height:105px;width:75px;object-fit:contain;">
                  
                  <div v-else class="rounded mr-3" style="height:105px;width:75px;object-fit:contain;display:flex;align-items:center;justify-content:center;">
                    <i class="uil uil-capture" style="font-size:2rem;color:rgba(0,0,0,0.1)"></i>
                  </div>
                </div>
                <div class="col-md-8" style="display:flex;align-items:center">
                  <div>
                   <h5 class="mb-0" style="color:#212529;">{{ item.title }}</h5>
                    <a style="color:#2a2a72;">
                      <span style="width:40px;margin-right:4px;">@</span>
                      <span v-if="item.newark_link" style="width:40px;margin-right:5px;">NW</span>
                      <span v-if="item.powerhouse_link" style="width:40px;margin-right:5px;">JC</span>
                      <span v-if="item.hoboken_link" style="width:40px;margin-right:5px;">HB</span>
                    </a>
                  </div>
                </div>
                
            </div>
            </div>
          </div>
          <div style="width:100%;height:45px;display:flex;justify-content:center;">
            <infinite-loading @infinite="infiniteHandler" style="margin-left:-20px"></infinite-loading>
          </div>
          
          
        </div>
        <div v-else-if="loading" style="height:100%;width:100%;display:flex;justify-content:center;align-items:center;">
          <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div v-else class="row p-4 ml-4 mt-1">
        
          <div v-for="(item, index) in $store.state.displayResults" :key="item.id" class="col-sm-6 col-lg-6 align-items-center mb-3 list-group-item-action p-2">
            <div @click="clickRouteFunc(item, index, 'displayresults')" :class="'ref'+item.id" style="cursor:pointer;">
              <div class="row">
                <div class="col-md-3" style="display:flex;justify-content:center;">
                  <img v-if="item.img_src" :src="item.img_src" class="rounded mr-3" style="height:105px;width:75px;object-fit:contain;">
                  
                  <div v-else class="rounded mr-3" style="height:105px;width:75px;object-fit:contain;display:flex;align-items:center;justify-content:center;">
                    <i class="uil uil-capture" style="font-size:2rem;color:rgba(0,0,0,0.1)"></i>
                  </div>
                  
                 
                </div>
                <div class="col-md-8" style="display:flex;align-items:center">
                  <div>
                   <h5 class="mb-0" style="color:#212529;">{{ item.title}}</h5>
                    <a style="color:#2a2a72;">
                      <span style="width:40px;margin-right:4px;">@</span>
                      <span v-if="item.newark_link" style="width:40px;margin-right:5px;">NW</span>
                      <span v-if="item.powerhouse_link" style="width:40px;margin-right:5px;">JC</span>
                      <span v-if="item.hoboken_link" style="width:40px;margin-right:5px;">HB</span>
                    </a>
                  </div>
                </div>
                
            </div>
            </div>
          </div>
          
        </div>
        
    </div> 
</div>
</template>
<script>
import _ from "lodash";
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components: {
      InfiniteLoading,
    },
    created() {
      this.fetchWine();
      console.log('created');
      
    },
    
    data() {
      return {
        loading: true,
        fetchWineItems: '',
        query: '',
        winelist: '',
        displayResults: '',
        displayResultsCount: '',
        page: 1,

      }
    },
    methods: {
      fetchWine() {
          if(this.$store.state.posCategory != 'market') {
            this.$store.state.page = 1;
            this.$store.state.posCategory = 'market';
            this.$store.state.posItem = '';
            this.$store.state.query = '',
            this.$store.state.displayResults = [];
            axios.get('api/fetchfood').then(response => {
            this.$store.state.items = response.data.food.data 
            console.log(response.data.food.data);
          
            }).then(data2 => {
              this.loading = false;
              this.$store.state.page += 1;
            });
          } else {
            this.loading = false;
            if(this.$store.state.posItem) {
              this.scrollToItem();
            }
          }  
          
        
      },
       infiniteHandler($state) {
         
           axios.get('api/fetchfood', {params: {page:this.$store.state.page}}).then(response => {
           if(response.data.food.data) {
              this.$store.state.page += 1;
              this.$store.state.items.push(...response.data.food.data);
              $state.loaded();
              this.loading = false;
           } else {
              $state.complete();
           }
            
          
          }).then(data2 => {
            this.loading = false;
          });
         
       },
       clickRouteFunc(item, index, where) {
         console.log('ellllooo');
         console.log(item.id);
         this.$store.state.posItem = 'ref' + item.id;
         this.$router.push({name: 'admin.items.view', params:{slug: item.slug}, query: {item: item, index:index, where: where }});
       },
       scrollToItem:_.debounce(function() {
          const el = this.$el.getElementsByClassName(this.$store.state.posItem)[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth', block: "center"});
            }
       }, 400),
      searchQuery:_.debounce(function() {
        this.loading = true;
        axios.get('api/searchfood?query=' + this.$store.state.query).then(response => {
          console.log(response.data.SearchResult.data);
          console.log(response.data.Count);
          this.displayResultsCount = response.data.Count;
          this.$store.state.displayResults = response.data.SearchResult.data;
        }).then(data2 => {
          this.loading = false;
        });
    },200),
    },
}
</script>