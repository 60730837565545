<template>
<div>

    <div style="position:absolute;bottom:0px;height:calc(100%);width:100%;overflow-x:hidden;overflow-y:scroll;">
        
        <div v-if="!loading" class="container-fluid px-lg-0">
        <div class="row no-gutters">
          <div style="position:absolute;left:2rem;top:1.45rem;z-index:4">
            <button @click="$router.go(-1)" class="btn" style="z-index:999" ><i class="uil uil-arrow-left" style="font-size:1.25rem;font-weight:200;"></i>
            </button></div>
          <div class="col-xl-4 pt-5 order-lg-2 mb-5" style="border-left: 2px solid rgba(0,0,0,0.1)">
            <div class="row justify-content-center sticky-top no-gutters">
              <div class="col-lg-10">
                <div class="col-md-12 mb-2" style="display:flex;justify-content:center;">
                 
                    
                      
                        <img v-if="item.img_src" :src="item.img_src" style="height:25vh;width:auto;object-fit:contain;" class="rounded mb-4">
                  
                        <div v-else style="height:25vh;width:auto;object-fit:contain;display:flex;align-items:center;justify-content:center;" class="rounded mb-4">
                          <i class="uil uil-capture" style="font-size:4rem;color:rgba(0,0,0,0.1)"></i>
                        </div>
                        
                      
     
                </div>
                
                <dl class="row ">
                  <dt class="col-3 mb-2">Name</dt>
                  <dd class="col-9 mb-2">{{ item.title}}</dd>

                  <dt v-if="item.type == 'wine'" class="col-3 mb-1">Grape</dt>
                  <dd v-if="item.type == 'wine'" class="col-9 mb-1">{{ item.varietal}}</dd>

                  <dt class="col-3 mb-1">Style</dt>
                  <dd class="col-9 mb-1">{{ item.style}}</dd>

                  <dt class="col-3 mb-1">Region</dt>
                  <dd class="col-9 mb-1">{{ item.region}}  <span v-if="item.region && item.country">, </span>{{item.country}}</dd>

                  <dt class="col-4 mt-3 text-center">Newark</dt>
                  <dt class="col-4 mt-3 text-center">Jersey City</dt>
                  <dt class="col-4 mt-3 text-center">Hoboken</dt>
                  
                  <dd class="col-4 text-center">
                    <a v-if="item.newark_link && item.newark_link >= 10" style="color:black;" :href="item.newark_link" target="_blank">
                      <i class="fas fa-external-link-alt" style="font-size:0.85rem"></i>
                    </a>
                    <a v-else-if="item.newark_link && item.newark_link <= 10" style="color:black;" target="_blank">
                      <i class="fas fa-external-link-alt" style="font-size:0.85rem"></i>
                    </a>
                  </dd>
                  <dd class="col-4 text-center">
                    <a v-if="item.powerhouse_link && item.powerhouse_link.length >= 10" style="color:black;" :href="item.powerhouse_link" target="_blank">
                      <i class="fas fa-external-link-alt" style="font-size:0.85rem"></i>
                    </a>
                    <span v-else-if="item.powerhouse_link && item.powerhouse_link.length <= 10" style="color:black;">
                      <i class="fas fa-external-link-alt" style="font-size:0.85rem"></i>
                    </span>
                  </dd>
                  <dd class="col-4 text-center">
                    <a v-if="item.hoboken_link && item.hoboken_link.length >= 10" style="color:black;" :href="item.hoboken_link" target="_blank">
                      <i class="fas fa-external-link-alt" style="font-size:0.85rem"></i>
                    </a>
                    <span v-else-if="item.hoboken_link && item.hoboken_link.length <= 10" style="color:black;">
                      <i class="fas fa-external-link-alt" style="font-size:0.85rem"></i>
                    </span>         
                  </dd>
                  
                </dl>
                <hr>
                <div class="d-flex align-items-center">
                  <span class="text-small mr-1">Shelf Talker:</span>
                  <div class="d-flex mx-2">
                    <button v-if="!previewing" @click="showPreview()" class="btn btn-round btn-primary mx-1" style="display:flex;align-items:center;justify-content:center;">
                      <i class="uil uil-image-download" style="font-size:1rem;"></i>
                    </button>
                    <button v-if="previewing" @click="closePreview()" class="btn btn-round btn-primary mx-1" style="display:flex;align-items:center;justify-content:center;">
                      <i class="uil uil-image-times" style="font-size:1rem;"></i>
                    </button>
                    <button @click="addToShelf()" class="btn btn-round btn-primary mx-1" style="display:flex;align-items:center;justify-content:center;">
                      <i class="uil uil-plus" style="font-size:1.34"></i>
                    </button>
                  </div>
                    
                    
                </div>
              </div>
            </div>
          </div>
          <div v-if="!previewing" class="col" style="">
            <div class="row" style="display:flex;justify-content:center;margin-top:-24px;">
              <div class="col-md-8" style="display:flex;justify-content:center;">
                <div class="row mt-4" style="displa:flex;justify-content:center">

                
                  
                  <div class="col-md-12 mb-4" style="display:flex;justify-content:center;">
                    <h3 style="color:rgba(0,0,0,0.6);font-weight:300"></h3>
                  </div>
                      
                  
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color:rgba(0,0,0,0.6)">Title *</label>
                        <input name="contact-name" type="text" v-model="item.title" class="form-control" required="">
                        <div class="invalid-feedback">
                          Please type your name.
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">ShelfTalker Description</label>
                        <textarea class="form-control" name="contact-message" rows="6" v-model="item.shelf_description"></textarea> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Description</label>
                       
                        <quill-editor 
                          class="form-control" style="padding-left:0px;padding-right:0px;min-height:270px;max-height:450px;"
                          ref="myQuillEditor"
                           v-model="item.description"
                           :content.sync="item.description"
                           :options="editorOption"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Item Remarks</label>
                        <textarea class="form-control" name="contact-message" rows="6" v-model="item.item_remark"></textarea> 
                      </div>
                    </div>
                    
                    <div v-if="item.type == 'wine'" class="col-md-12">
                      <div class="form-group" style="margin-bottom:0px;padding-bottom:0px;">
                        <label style="color: rgba(0,0,0,0.6)">Wine Type</label>
                      </div>
                      <div class="form-group">
                        
                        <select @change="wineTypeSelect" v-model="item.wine_type" class="custom-select">
                          <option selected="">Select Type</option>
                          <option v-for="i in printLayout" :key="i" :value="i">{{i}}</option>
                        
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="injected-svg icon" data-src="https://leap.mediumra.re/assets/img/icons/interface/arrow-caret.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <path d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z" fill="#212529"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Price</label>
                        <input name="contact-name" type="text" v-model="item.price" class="form-control" required=""> 
                      </div>
                    </div>
                    <div v-if="item.type == 'wine'" class="col-md-12">
                      <div class="form-group mt-1">
                        <label class="mb-2" style="color: rgba(0,0,0,0.6)">Farm Practice</label>
                        <div class="row mt-1">
                          <div class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_natural" class=" mr-1" type="checkbox">
                              <label>Natural</label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3">
                              <input v-model="item.farmpractice_biodynamic" class="mr-1" type="checkbox">
                              <label >Biodynamic</label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3">
                              <input v-model="item.farmpractice_limited" class="mr-1" type="checkbox">
                              <label>Limited</label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_organic" class="mr-1" type="checkbox">
                              <label>Organic</label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_kosher" class="mr-1" type="checkbox">
                              <label >Kosher</label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class=" mb-3" style="">
                              <input v-model="item.farmpractice_sustainable" class="mr-1" type="checkbox" style="">
                              <label >Sustainable</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'spirits'" class="col-md-12">
                      <div class="form-group mt-1">
                        <label class="mb-2" style="color: rgba(0,0,0,0.6)">Farm Practice</label>
                        <div class="row mt-1">
                          
                          
                         
                          <div class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_organic" class="mr-1" type="checkbox">
                              <label>Organic</label>
                            </div>
                          </div>
                         
                         
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'beer'" class="col-md-12">
                      <div class="form-group mt-1">
                        <label class="mb-2" style="color: rgba(0,0,0,0.6)">Farm Practice</label>
                        <div class="row mt-1">
                          
                          
                         
                          <div class="col-4">
                            <div class=" mb-3">
                              <input v-model="item.farmpractice_limited" class="mr-1" type="checkbox">
                              <label>Limited</label>
                            </div>
                          </div>
                         
                         
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Producer</label>
                        <input name="contact-name" type="text" v-model="item.producer" class="form-control" required=""> 
                      </div>
                    </div>
                    <div v-if="item.type == 'food'" class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Food Category</label>
                        <input name="contact-name" type="text" v-model="item.foodcategory" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Country</label>
                        <input name="contact-name" type="text" v-model="item.country" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Region</label>
                        <input name="contact-name" type="text" v-model="item.region" class="form-control" required=""> 
                      </div>
                    </div>
                    <div v-if="item.type == 'food'" class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Subregion</label>
                        <input name="contact-name" type="text" v-model="item.subregion" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Grape</label>
                        <input name="contact-name" type="text" v-model="item.varietal" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Vintage</label>
                        <input name="contact-name" type="text" v-model="item.vintage" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Style</label>
                        <input name="contact-name" type="text" v-model="item.style" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Body</label>
                        <input name="contact-name" type="text" v-model="item.body" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Size</label>
                        <input name="contact-name" type="text" v-model="item.size" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Powerhouse Link</label>
                        <input name="contact-name" type="text" v-model="item.powerhouse_link" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Newark Link</label>
                        <input name="contact-name" type="text" v-model="item.newark_link" class="form-control" required=""> 
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label style="color: rgba(0,0,0,0.6)">Hoboken Link</label>
                        <input name="contact-name" type="text" v-model="item.hoboken_link" class="form-control" required=""> 
                      </div>
                    </div>
                    
                    <div class="col-md-12" style="display:flex;justify-content:center;">
                      
                      <button v-if="!delCheckedValue" type="submit" class="btn btn-primary btn-loading mt-4 m-6" @click="updateShelf" data-loading-text="Sending">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon" data-src="assets/img/icons/theme/code/loading.svg">
                            <title>Icon For Loading</title>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g>
                                    <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                                </g>
                                <path d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "></path>
                            </g>
                        </svg>
                                            <span>Update Shelf</span>
                      </button>
                      <button v-else type="submit" class="btn btn-danger btn-loading mt-4 m-6" @click="delShelf" data-loading-text="Sending">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon" data-src="assets/img/icons/theme/code/loading.svg">
                            <title>Icon For Loading</title>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g>
                                    <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                                </g>
                                <path d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "></path>
                            </g>
                        </svg>
                                            <span>Remove Shelf</span>
                      </button>

                      <div style="position:absolute;left:0px;top: calc(50% - 12px);height:45px;width:45px;">
                        <div class="custom-control custom-switch">
                        <input type="checkbox" @change="changeDelete" class="custom-control-input" id="customSwitch1">
                        <label class="custom-control-label" for="customSwitch1"></label>
                      </div>
                      </div>
                    </div>
                  </div>
              
                
              </div>
            </div>
          </div>
          <div v-else class="col" style="display:flex;justify-content:center;">
           
            <div class="row">
              <div v-if="item.type == 'wine'" class="col-12" style="display:flex;justify-content:center">
                <preview-wine2 :previewValue='item' style="-webkit-transform:scale(0.9);margin-top:35px;"></preview-wine2>
              </div>
              <div v-if="item.type == 'wine'" class="col-12" style="display:flex;justify-content:center">
                <preview-wine  :previewValue='item' style="-webkit-transform:scale(1.2);margin-top:-20px;"></preview-wine>
              </div>
              <div v-if="item.type == 'spirits'" class="col-12" style="display:flex;justify-content:center">
                <preview-spirits2  :previewValue='item' style="-webkit-transform:scale(0.7);margin-top:0px;"></preview-spirits2>
              </div>
              <div v-if="item.type == 'spirits'" class="col-12" style="display:flex;justify-content:center">
                <preview-spirits  :previewValue='item' style="-webkit-transform:scale(1);margin-top:-20px;"></preview-spirits>
              </div>
              

              <div v-if="item.type == 'beer'" class="col-12" style="display:flex;justify-content:center">
                <preview-beer2 :previewValue='item' style="-webkit-transform:scale(1);margin-top:25px;"></preview-beer2>
              </div>
              <div v-if="item.type == 'beer'" class="col-12" style="display:flex;justify-content:center">
                <preview-beer  :previewValue='item' style="-webkit-transform:scale(1.2);margin-top:25px;"></preview-beer>
              </div>

              
              <div v-if="item.type == 'food'" class="col-12" style="display:flex;justify-content:center">
                <preview-market2  :previewValue='item' style="-webkit-transform:scale(1);margin-top:25px;"></preview-market2>
              </div>
              <div v-if="item.type == 'food'" class="col-12" style="display:flex;justify-content:center">
                <preview-market :previewValue='item' style="-webkit-transform:scale(1.2);margin-top:25px;"></preview-market>
              </div>

              
              
            </div>
              
              
          </div>
        </div>
        
        </div>
        <div v-else style="height:100%;width:100%;display:flex;justify-content:center;align-items:center;">
          <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
          </div>
        </div>
        
    </div> 
    
</div>
</template>
<script>
import axios from 'axios';


import previewWine from "./preview/PreviewWine";
import previewWine2 from "./preview/PreviewWine2";
import previewSpirits from "./preview/PreviewSpirits";
import previewSpirits2 from "./preview/PreviewSpirits2";
import previewBeer from "./preview/PreviewBeer";
import previewBeer2 from "./preview/PreviewBeer2";
import previewMarket from "./preview/PreviewMarket";
import previewMarket2 from "./preview/PreviewMarket2";


import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  
    components: {
      quillEditor,
      previewWine, previewWine2, previewSpirits, previewSpirits2, previewBeer, previewBeer2, previewMarket, previewMarket2
    },
    data() {
      return {
        item: {},
        index: '',
        where: '',
        loading: false,
        previewing: false,
        wineTypeValue: '',
        printLayout: {
          red: 'Red Wine',
          white: 'White Wine',
          sparkling: 'Sparkling Wine',
          rose: 'Rose Wine',
          orange: 'Orange Wine'
        },
        editorOption: {
          theme: 'bubble',
          
          modules: {
            toolbar: [
               ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'size': [ 'small', false, 'large', 'huge' ] }],
              
              [{ 'align': [] }],
         
              ['clean']
            ]
          }
        },
        delCheckedValue: false,
      }
    },
    created() {
      
      this.item = this.$route.query.item;
      this.index = this.$route.query.index;
      this.where = this.$route.query.where;
    },
    methods: {
      fetchItem() {
        axios.get('/api/returnitem?query='+ this.$route.params.slug).then(response => {
            console.log(response.data.item);
            this.item = response.data.item;
            
          }).then(data2 => {
            this.loading = false;
            
          });
      
      },
      updateShelf() {
        this.loading=true;
        console.log('updating shelf');
        
        console.log(this.item);
        axios.post('/api/shelf/update', this.item)
          .then(response => {
            this.loading= false;
            this.$toasted.show('Updated!')
            console.log(response.data.success);
          }).then(resp2 => {

            if(this.where == 'displayresults') {
              this.$store.state.displayresults[this.index] = this.item;
            } else if(this.where == 'items') {
              this.$store.state.items[this.index] = this.item;
            }
          }).catch(err => {
            this.loading = false;
            this.$toasted.error('Something\'s gone wrong, try again')
          });
      },
      delShelf() {
        this.loading=true;
        

          if(confirm('Delete Shelf?')) {
            console.log('Deleting shelf');
       
            console.log(this.item);
            axios.post('/api/shelf/delete', this.item)
              .then(response => {
                if(this.where == 'displayresults') {
                  this.$store.state.displayresults.splice(this.index, 1);
                } else if(this.where == 'items') {
                  this.$store.state.items.splice(this.index, 1);
                }
                console.log(response.data.success);
              }).then(resp2 => {
                this.loading= false;
                this.$router.back();
                this.$toasted.show('Successfully Removed!')
              });
          }
       
      }, 
      wineTypeSelect(event) {
        if(event.target.value !== null || event.target.value == '') {
          this.item.wine_type = event.target.value;
        }
        
       
      },
      changeDelete(event) {
        console.log("change delete active");
        console.log(event.target.checked);
        this.delCheckedValue = event.target.checked;
        
      },
      showPreview() {
            this.previewing = true;
      },
      closePreview() {
            this.previewing = false;
      },
      addToShelf() {
            this.$store.commit('addToShelf', this.item);
            this.$toasted.success('Added!');
        },
    },
}
</script>
<style>
.ql-container {
  font-family: inherit;
  font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
</style>